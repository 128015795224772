.top-nav {
    transition: all .5s ease-in !important;
    background: var(--ck-lg-bg-0);
    box-shadow: var(--ck--shadow-1);
}

.top-nav:hover {
    box-shadow: var(--ck-shadow-btn);
}

.navbar-toggler.collapsed:focus, .navbar-toggler.collapsed:active {
    border: none !important;
    box-shadow: none !important;
}

.navbar-toggler.collapsed .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

#offcanvasNavbar-expand-md,
.offcanvas {
    background: var(--ck-color-dark-2);
    color: var(--ck-color-white-1);
}

.offcanvas-body a.nav-link{
    color: var(--ck-color-white-0);
    position: relative;
    transition: 1s;
    font-weight: bold;
}

.offcanvas-body a.nav-link.active{
    color: var(--ck-color-white-0) !important;
}

.offcanvas-body a.nav-link:not(.dropdown-toggle):after{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    transition: transform 500ms ease-in-out;
    background: var(--ck-color-dark-3);
}

.offcanvas-body a.nav-link.active:after,
.offcanvas-body a.nav-link:hover:after{
transform: scaleX(1);
}

.dropdown-toggle.show.nav-link{
    color: var(--ck-color-white-0) !important;
}

.dropdown-menu.show{
    background: var(--ck-color-dark-2);
    color: var(--ck-color-white-1);
}
.dropdown-menu .dropdown-item{
    color: var(--ck-color-white-1);
}
.dropdown-menu .dropdown-item:hover,
    .dropdown-menu .dropdown-item:active{
    background-color: var(--ck-color-dark-3);
    color: var(--ck-color-white-0);
}

.account-header-dropdown{
    background: var(--ck-color-dark-2);
    
}
.account-header-dropdown:hover{
    background: var(--ck-color-dark-3);
}

.account-header-dropdown a{
    display: flex;
    align-items: center;
}

.avtar-by-letter{
    /* background: var(--ck-color-primary-0); */
    width: 26px;
    display: inline-block;
    text-align: center;
    border-radius: 50px;
}


#footer{
    background: var(--ck-color-dark-2);
}
.footer_icons{
    display: block;
    padding :10px;
    border-radius: 30px;
}
.footer_icons li, footer .list-unstyled li{
    list-style: none;
    margin-top:10px;
    border-radius: 50%;
    width:30px;
    height:30px;
    text-align: center;
    transition: .5s;
    border: 1px solid gray;
}
.footer_icons li a,footer .list-unstyled li a{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    text-align: center;
    color:gray;
    margin-top: 2px;
}
.footer_icons li:hover,footer .list-unstyled li:hover {
    box-shadow: 1px 1px 4px rgb(219, 179, 179), -1px -1px 4px rgb(218, 109, 109);
}

.footer_icons li:nth-child(1) a:hover,
footer .list-unstyled li:nth-child(1) a:hover{
    color: #e4405f !important;
}
.footer_icons li:nth-child(2) a:hover,
footer .list-unstyled li:nth-child(2) a:hover{
    color: #0088cc !important;
}
.footer_icons li:nth-child(3) a:hover,
footer .list-unstyled li:nth-child(3) a:hover{
    color: #0077B5 !important;
}
.footer_icons li:nth-child(4) a:hover,
footer .list-unstyled li:nth-child(4) a:hover{
    color:  #c4302b !important;
}


#footer a{
    color: var(--ck-color-white-1) !important;
}


#footer a:hover{
    color: var(--ck-color-white-0) !important;   
}